import React, { forwardRef } from 'react'


type NavListProps = {
  className?: string
  listClassName?: string
  'aria-label': string
  children: React.ReactNode
}

const NavList = forwardRef<HTMLElement, NavListProps>((props, ref) => {
  const { children, className, listClassName, 'aria-label': ariaLabel } = props

  return (
    <nav className={className} ref={ref} aria-label={ariaLabel}>
      <ul className={listClassName}>{children}</ul>
    </nav>
  )
})


export default NavList
